
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Meta from "../../components/Meta";
import AggregratorMain from "../../components/nft-aggregator/AggregratorMain";
import Hero from "../../components/nft-aggregator/Hero";
const Home_13 = () => {
    return (<main>
      <Meta title="Home 13"/>
      <Hero />
      <AggregratorMain />
    </main>);
};
export default Home_13;

    async function __Next_Translate__getStaticProps__18dc829eedc__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/home/home_13',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18dc829eedc__ as getStaticProps }
  